'use strict';

var main = require('integrations/main');

main.baseFiles.base = require('./product/base');
main.baseFiles.cart = require('./cart');
main.baseFiles.footer = require('./components/footer');
main.baseFiles.detail = require('./product/detail');
main.baseFiles.formErrors = require('./checkout/formErrors');

module.exports = main;
