'use strict';

var integrationsCore = require('integrations/product/base');

integrationsCore.addToCartTile = function () {
    $(document).on('click', 'button.add-to-cart-tile', function () {
        var addToCartUrl;
        var pid;
        var $productTile = $(this).closest('.product-tile');

        if($productTile.length) {
            addToCartUrl = $productTile.find('.add-to-cart-url').val();
            pid = $productTile.data('pid');
        }

        var form = {
            pid: pid,
            quantity: 1
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    integrationsCore.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    integrationsCore.methods.miniCartReportingUrl(data.reportingURL);
                }
            });
        }
    });
}

// Takes the new product's (a variant) name (returned through Product-Variation > handleVariantResponse) and replaces the current one with it
function updateProductName(productName) {
    var productNameContainer = $('.product-detail .product-name');

    if(productNameContainer && productName) {
        productNameContainer.html(productName);
    }
}

//Replaces the "collection"/category link with a new one based on the current product returned through Product-Variation > handleVariantResponse
function updateCategoryLink(categoryData) {
    var categoryLinkContainer = $('.product-detail .category-link .col');

    if (categoryLinkContainer && categoryData) {
        var categoryType = categoryLinkContainer.data('category-type');
        var categoryLink = '<a href="' + categoryData.url + '" title="' + categoryData.name + '">' + categoryData.name + ' ' + categoryType + '</a>';

        categoryLinkContainer.html(categoryLink);
    }
}

//Takes the tabs html returned from Product-Variation and replaces the current tabs with it
function updateTabs(tabsHtml) {
    var tabsContainer = $('.tabs-container');

    if (tabsContainer) {
        tabsContainer.html(tabsHtml);
    }
}

//For explicit recommendations this takes the recommendations html returned from Product-Variation and replaces the current explicit recommendations with it
function updateRecommendations(recommendationsHtml) {
    var recommendationsContainer = $('.list-recommendations');
    if (recommendationsContainer) {
        recommendationsContainer.empty().html(recommendationsHtml);
    }
}

function updateBIS(product) {
    var buttonContainer = $('#qty-cart-container');
    if(!product.available) {
        buttonContainer.addClass('soldout');
        $('#soldoutModal [name=pid]').val(product.id);
        $('#soldoutModal .name').text(product.productName);
    } else {
        buttonContainer.removeClass('soldout');
    }
}

integrationsCore.updatePDPView = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        try {
            var product = response.data.product;
            if (!product.isMaster && product.id && yotpo) {
                $('#yotpo-reviews-top-div').attr('data-product-id', product.id);
                $('#yotpo-bottomline-top-div').attr('data-product-id', product.id);
                var api = new Yotpo.API(yotpo);
                api.refreshWidgets();
            }
            if (product.productName) {
                updateProductName(product.productName);
            }
            if (product.categoryData && 'url' in product.categoryData) {
                updateCategoryLink(product.categoryData);
            }
            var tabsHtml = ''
            if (product.tabsHtml) {
                tabsHtml = product.tabsHtml;
            }
            updateTabs(tabsHtml);

            if($('.pdp-assets').length == 0) {
                $('#pdpAssets').html('<div class="pdp-assets"></div>');
            }

            if(product.pdpAssetIds) {
                $('#pdpAssets .pdp-assets').empty();
                var url = $('#pdpAssets').data('render-url');
                product.pdpAssetIds.forEach(function (pdID) {
                    var data = 'cid=' + pdID;
                    $.ajax({
                        url: url,
                        method: 'GET',
                        data: data,
                        dataType: 'html',
                        success: function (data) {
                            var pdpAsset = $('<div class="pdp-asset"></div>').html(data);
                            $('#pdpAssets .pdp-assets').append(pdpAsset);
                            $('body').trigger('ajax:load.ajaxEvents', [$('.pdp-assets')]);
                        }
                    });
                });
            } else {
                $('#pdpAssets').empty();
            }

            var recommendationsHtml = '';
            if (product.recommendationsHtml) {
                recommendationsHtml = product.recommendationsHtml;
            }
            updateRecommendations(recommendationsHtml);

            //update availability button text
            updateBIS(product);
        } catch (e) {
            // fail gracefully
        }
    });
};

integrationsCore.methods.handlePostCartAdd = function (response) {
    // Here you can take over the handlePostCartAdd function in our client overlay
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast on error, otherwise show mini-cart
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        this.chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if (response.error) {
            if ($('.add-to-cart-messages').length === 0) {
                $('body').append(
                    '<div class="add-to-cart-messages"></div>'
                );
            }

            $('.add-to-cart-messages').append(
                '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                + response.message
                + '</div>'
            );

            setTimeout(function () {
                $('.add-to-basket-alert').remove();
            }, 5000);
        } else {
            var url = $('.minicart').data('action-url');

            $('.minicart .popover').addClass('show');
            $('.minicart .popover').spinner().start();

            $.get(url, data => {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                $.spinner().stop();
                $('body').trigger('minicart:loaded', $('.minicart .popover'));
            });

            setTimeout(function () {
                $('.minicart .popover').removeClass('show');
            }, 6000);
        }
    }
};

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
 integrationsCore.methods.getChildProducts = function () {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).attr('data-pid'),
            quantity: parseInt($(this).find('.quantity.non-input-label').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

integrationsCore.backInStock = function () {
    $('#bis-form').submit(function (e) {
        e.preventDefault();
        var $form = $(this);
        var pid = $('input[name=pid]', $form).val();
        var email = $('input[name=email]', $form).val();

        if (typeof _ltk != 'undefined') {
            _ltk.Alerts.AddAlert(email, pid, 'BIS');
            _ltk.Alerts.Submit();
        }
        $('.success', $form).fadeIn(500);
    });

    $('#soldoutModal').on('hidden.bs.modal', function (e) {
        $('#bis-form').trigger('reset');
        $('#bis-form .success').fadeOut(500);
    });
};

module.exports = integrationsCore;
