let googleMapsPromise = null;

module.exports = function() {
    if (googleMapsPromise) {
        return googleMapsPromise;
    }

    if (!window.CachedData.googleMapsApi || window.google) {
        googleMapsPromise = Promise.resolve();
    } else {
        googleMapsPromise = $.getScript(window.CachedData.googleMapsApi);
    }

    return googleMapsPromise;
}
