'use strict';

var core = require('core/product/detail');

core.updateHeadlinesAndTabs = function() {
    $('body').on('product:afterAttributeSelect', function (e, data) {
        if (data && data.data && data.data.product && data.container) {
            var $productContainer = data.container;
            var product = data.data.product;

            if (product.headline) {
                $productContainer.find('.product-headline')
                    .show()
                    .html(product.headline);
            } else {
                $productContainer.find('.product-headline')
                    .hide();
            }

            if (product.headlineDescription) {
                $productContainer.find('.product-headlinedescription')
                    .show()
                    .html(product.headlineDescription);
            } else {
                $productContainer.find('.product-headlinedescription')
                    .hide();
            }

            if (product.collapsibleContentHTML) {
                $productContainer.find('.main-content-item')
                    .show()
                    .replaceWith(product.collapsibleContentHTML);
            } else {
                $productContainer.find('.main-content-item')
                    .hide();
            }

            if (product.tabsHTML) {
                $productContainer.find('.tabs')
                    .show()
                    .replaceWith(product.tabsHTML);

                if (product.tabs.length > 0 && product.tabs[0].content) {
                    $(".product-details-description > .col > p").replaceWith(product.tabs[0].content)
                }
            } else {
                $productContainer.find('.tabs')
                    .hide();
            }
        }
    });
}


module.exports = core;
