'use strict';

const footer = require('core/components/footer');

//Move Back to Top component if there is a promo at the bottom right of the screen
footer.adjustBackToTop = () => {
    const targetNode = document.body;
    const config = { attributes: true, childList: true, subtree: true };

    const callback = function (mutationsList, observer) {
        const backToTop = $(".back-to-top");
        if (backToTop !== undefined) {
            if (document.getElementById("ltkPopupButtonTriggerContainer") && !backToTop.hasClass("back-to-top-extra-height")) {
                backToTop.addClass("back-to-top-extra-height");
            } else if (!document.getElementById("ltkPopupButtonTriggerContainer") && backToTop.hasClass("back-to-top-extra-height")) {
                backToTop.removeClass("back-to-top-extra-height");
                observer.disconnect();
            }
        }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
};

window.addEventListener("load", function () {
    if ($('.page').data('action') == 'Home-Show') {
        yotpo.refreshWidgets();
    }
});

module.exports = footer;
