'use strict';

var base = require('core/cart/cart');

base.revealPromoCode = function() {
  $('body').on('click', '.js-promo-code-reveal', function() {
    $('.promo-code-wrapper').addClass('show');
  });
}

module.exports = base;