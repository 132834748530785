'use strict';

var baseCart = require('integrations/cart');
var cart = require('./cart/cart');
baseCart.baseFiles.cart = function () {
    cart.init.call(cart);
    cart.revealPromoCode();
}

module.exports = baseCart;
